@import url('../../App.css');

.hero-profile-container {
  color: #ffffff;
}

.hero-profile {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-profile p {
  font-size: 1.25rem;
  font-weight: 500;
}

.hero-profile h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0rem 0 1rem 0;
}

.hero-profile span {
  font-size: 1.563rem;
  font-weight: 500;
  margin: 1rem 0 1rem 0;
}

.hero-profile img {
  width: 6.25rem;
  border-radius: 100%;
  margin-bottom: 1rem;
}

.hero-profile-tagline {
  margin: 1rem 0 2rem 0;
  background-color: rgba(11, 11, 11, 0.7);
  padding: 0.5rem;
  border-radius: 1rem;
}

.hero-profile-button-light-theme {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  font-size: 0.75rem;
  color: var(--brand-color-blue-main);
  background: var(--brand-color-grey);
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 5rem;
  box-shadow: 0rem 0.1rem 0.313rem 0rem rgb(158, 158, 158);
  cursor: pointer;
  width: 15rem;
  height: 2.5rem;
  transition: 0.5s background linear;
}

.hero-profile-button-light-theme:hover {
  background: #ffffff;
}

.hero-profile-button-dark-theme {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  font-size: 0.75rem;
  color: var(--brand-color-white);
  background: var(--main-font-color);
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 5rem;
  box-shadow: 0rem 0.1rem 0.313rem 0rem rgb(51, 51, 51);
  cursor: pointer;
  width: 15rem;
  height: 2.5rem;
  transition: 0.5s background linear;
}

.hero-profile-button-dark-theme:hover {
  background: #1f1f1f;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  /* CSS */
}

/* 
	##Device = Laptops, Desktops
	##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
}

/* 
	##Device = Tablets, Ipads (portrait)
	##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  /* CSS */
  .hero-profile img {
    display: block;
  }
  .hero-profile p {
    display: none;
  }
  .hero-profile h1 {
    font-size: 1.5rem;
  }
  .hero-profile-tagline {
    display: none;
  }
  .hero-profile-button-light-theme,
  .hero-profile-button-dark-theme {
    margin-top: 1rem;
    font-size: 0.5rem;
    height: 1rem;
    width: 10rem;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    left: 0;
  }

  .hero-profile span {
    font-size: 1rem;
  }
}

/* 
	##Device = Tablets, Ipads (landscape)
	##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
}

/* 
	##Device = Low Resolution Tablets, Mobiles (Landscape)
	##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
  .hero-profile img {
    display: none;
  }
  .hero-profile p {
    display: none;
  }
  .hero-profile h1 {
    font-size: 1.5rem;
  }
  .hero-profile-tagline {
    display: none;
  }
  .hero-profile-button-light-theme,
  .hero-profile-button-dark-theme {
    margin-top: 1rem;
    font-size: 0.5rem;
    height: 1rem;
    width: 10rem;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    left: 0;
  }

  .hero-profile span {
    font-size: 1rem;
  }
}

/* 
	##Device = Most of the Smartphones Mobiles (Portrait)
	##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  /* CSS */
  .hero-profile img {
    display: none;
  }
  .hero-profile p {
    display: none;
  }
  .hero-profile h1 {
    font-size: 1.5rem;
  }
  .hero-profile-tagline {
    display: none;
  }
  .hero-profile-button-light-theme,
  .hero-profile-button-dark-theme {
    margin-top: 1rem;
    font-size: 0.5rem;
    height: 1rem;
    width: 10rem;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    left: 0;
  }

  .hero-profile span {
    font-size: 1rem;
    background: var(--main-font-color);
    padding: 0.2rem;
  }
}
