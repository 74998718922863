@import url('../../App.css');

.theme-toggle-button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-around;
	font-size: 1.5rem;
	line-height: 1;
	width: var(--toggle-width);
	height: var(--toggle-height);
	padding: var(--toggle-padding);
	border: 0;
	border-radius: calc(var(--toggle-width) / 2);
	cursor: pointer;
	background: var(--color-bg-toggle);
	transition: background 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
}

.theme-toggle-button :focus {
	outline-offset: 5px;
}
.theme-toggle-button :focus:not(:focus-visible) {
	outline: none;
}
.theme-toggle-button :hover {
	box-shadow: 0 0 5px 2px var(--color-bg-toggle);
}

.theme-toggle-thumb {
	position: absolute;
	top: var(--toggle-padding);
	left: var(--toggle-padding);
	width: calc(var(--toggle-height) - (var(--toggle-padding) * 2));
	height: calc(var(--toggle-height) - (var(--toggle-padding) * 2));
	border-radius: 50%;
	background: white;
	transition: transform 0.25s ease-in-out;
}
