@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-font-family: 'Quicksand', sans-serif;
  --main-font-color: #2f2f3a;
  --main-font-size: 16px;
  --brand-color-blue-main: #0384c8;
  --brand-color-red: #ef2f4c;
  --brand-color-blue: #09d8ff;
  --brand-color-green: #11ef75;
  --brand-color-yellow: #ffca04;
  --brand-color-white: #fafafa;
  --brand-color-grey: #edf2fa;

  --toggle-width: 5rem;
  --toggle-height: 2.375rem;
  --toggle-padding: 0.25rem;
}

html {
  font-size: var(--main-font-size);
}

body,
body[data-theme='light'] {
  --color-text-primary: var(--main-font-color);
  --color-bg-primary: var(--brand-color-grey);
  --color-bg-toggle: var(--brand-color-blue);
}

body[data-theme='dark'] {
  --color-text-primary: var(--brand-color-white);
  --color-bg-primary: var(--main-font-color);
  --color-bg-toggle: var(--main-font-color);
}

body {
  background: var(--color-bg-primary);
  font-size: 1rem;
  color: var(--color-text-primary);
  font-family: var(--main-font-family);
  transition: background 0.25s ease-in-out;
}

.hero-container {
  position: relative;
}

.hero-header-container {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
}

.hero-bio-container {
  width: 100%;
  position: absolute;
  top: 10rem;
}

main {
  padding: 2rem;
}

.section-border-light-theme {
  border: 0.2rem solid var(--main-font-color);
  padding: 2rem;
  border-radius: 2rem;
}

.section-border-dark-theme {
  border: 0.2rem solid var(--brand-color-white);
  padding: 2rem;
  border-radius: 2rem;
}

.about-section {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.header-section {
  text-align: center;
  margin: 2rem 0 2rem 0;
}

.header-title-light {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 3.125rem;
  font-weight: 300;
  color: var(--main-font-color);
}

.header-title-dark {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 3.125rem;
  font-weight: 300;
  color: var(--brand-color-white);
}

.skills-section {
  display: flex;
  justify-content: center;
}

.logo-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.logo-container p {
  font-weight: 700;
  margin-top: 1rem;
}

.contact-section {
  display: flex;
  justify-content: center;
}

.footer-light-theme {
  background: var(--main-font-color);
  padding: 2rem;
  color: var(--brand-color-white);
  text-align: center;
}

.footer-dark-theme {
  background: var(--brand-color-grey);
  padding: 2rem;
  color: var(--main-font-color);
  text-align: center;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  /* CSS */
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  /* CSS */
  .about-section {
    display: flex;
    flex-flow: column-reverse nowrap;
    align-items: center;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  /* CSS */
  .about-section {
    display: flex;
    flex-flow: column-reverse nowrap;
    align-items: center;
  }
}

/* 
	##Device = Tablets, Ipads (landscape)
	##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
  .about-section {
    display: flex;
    flex-flow: column-reverse nowrap;
    align-items: center;
  }
}

/* 
	##Device = Low Resolution Tablets, Mobiles (Landscape)
	##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  /* CSS */
  .hero-bio-container {
    width: 100%;
    position: absolute;
    top: 7rem;
  }
  .about-section {
    display: flex;
    flex-flow: column-reverse nowrap;
    align-items: center;
  }
}

/* 
	##Device = Most of the Smartphones Mobiles (Portrait)
	##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  /* CSS */
  .hero-bio-container {
    width: 100%;
    position: absolute;
    top: 7rem;
  }
  .about-section {
    display: flex;
    flex-flow: column-reverse nowrap;
    align-items: center;
  }
}
