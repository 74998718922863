@import url('../../App.css');
.footer-text p:first-of-type {
	margin-bottom: 1rem;
}

.footer-text-link-light-theme {
	color: var(--brand-color-white);
}

.footer-text-link-dark-theme {
	color: var(--main-font-color);
}
