.about-profile-image svg {
	width: 50rem;
	max-width: 50rem;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
	/* CSS */
	.about-profile-image svg {
		width: 50rem;
		max-width: 50rem;
	}
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
	/* CSS */
	.about-profile-image svg {
		width: 30rem;
		max-width: 30rem;
	}
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
	/* CSS */
	.about-profile-image svg {
		width: 30rem;
		max-width: 30rem;
	}
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
	/* CSS */
	.about-profile-image svg {
		width: 30rem;
		max-width: 30rem;
	}
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
	/* CSS */
	.about-profile-image svg {
		width: 30rem;
		max-width: 30rem;
	}
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
	/* CSS */
	.about-profile-image svg {
		width: 20rem;
		max-width: 20rem;
	}
}
